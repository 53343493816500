<script>
import LogoKotas from '@/assets/landing-page/kotas_logo.svg';

export default {
  components: {
    LogoKotas,
  },

  data: () => ({
    isSuccess: false,
  }),

  methods: {
    copy() {
      this.isSuccess = true;
      const { servico, ...query } = this.$route.query || {};
      const queryString = convertQueryObjectToUrl(query);

      let url = `https://kotas.com.br?${queryString}`;

      if (servico) {
        url = `https://kotas.com.br/servico/${servico}?${queryString}`;
      }

      try {
        navigator.clipboard.writeText(url);
      } catch {}

      function convertQueryObjectToUrl(queryObject) {
        return Object.keys(queryObject)
          .map((key) => `${key}=${queryObject[key]}`)
          .join('&');
      }
    },
  },
};
</script>

<template>
  <div class="campanha">
    <div class="campanha__inner">
      <div class="campanha__inner__logo">
        <LogoKotas />
      </div>

      <h1>Este link é melhor visualizado no seu navegador</h1>

      <button
        v-gtm.click="{
          category: 'lp-campanha-instagram',
          action: 'click',
          label: 'copiar-link',
        }"
        class="btn"
        :class="{ 'btn--is-success': isSuccess }"
        @click="copy"
      >
        <template v-if="isSuccess">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path
              d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"
            />
            <rect x="9" y="3" width="6" height="4" rx="2" />
            <path d="M9 14l2 2l4 -4" />
          </svg>
        </template>
        <template v-else>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path
              d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"
            />
            <rect x="9" y="3" width="6" height="4" rx="2" />
          </svg>
          Copiar link
        </template>
      </button>

      <p>
        Clique no botão acima para copiar o link e cole no seu navegador de preferência para acessar
        o site do Kotas 😉
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.campanha {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 98vh;
  padding: 0.75rem;
  box-sizing: border-box;

  &__inner {
    max-width: 35rem;
    margin: 0 auto;
    background-color: white;
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
    box-sizing: border-box;

    &__logo {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & h1 {
      font-family: 'Mulish';
      text-align: center;
      color: rgb(41, 41, 41);
      font-weight: bold;
    }

    & p {
      text-align: center;
      font-family: 'Mulish';
      font-size: 1rem;
    }
  }

  @media (min-width: 1024px) {
    &__inner {
      padding: 3rem;
    }
  }
}

.btn {
  font-family: 'Mulish';
  background-color: #e9f0ff;
  color: #2575d5;
  font-weight: bold;
  transition: ease 0.1s;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #e1ebff;
  }
}

.btn--is-success {
  background-color: #e9fff3;
  color: #25d583;

  &:hover {
    background-color: #e1ffea;
  }
}
</style>
